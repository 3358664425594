<script setup lang="ts">
import { formatDateTime } from '../../helpers'

definePage({
  meta: {
    title: 'Campanhas',
    icon: 'share'
  }
})

const campaignStore = useStore('campaign')
</script>

<template>
  <aeria-crud collection="campaign"></aeria-crud>

  <aeria-panel
    float
    title="Execuções"
    v-model="campaignStore.modal"
    close-hint
    @overlay-click="() => (campaignStore.modal = false)"
  >
    <aeria-table>
      <template #thead>
        <tr>
          <th>Data/Hora</th>
          <th>Total</th>
          <th>Completas</th>
          <th>Pendentes</th>
          <th>Falhas</th>
          <th>Em execução</th>
          <th></th>
        </tr>
      </template>
      <template #tbody>
        <tr v-for="task in campaignStore.tasks">
          <td>{{ formatDateTime(task.created_at) }}</td>
          <td>{{ task.executions.total }}</td>
          <td>{{ task.executions.complete }}</td>
          <td>{{ task.executions.pending }}</td>
          <td>{{ task.executions.running }}</td>
          <td>{{ task.executions.failed }}</td>
        </tr>
      </template>
    </aeria-table>
  </aeria-panel>
</template>

<script setup lang="ts">
definePage({
  meta: {
    title: 'Broadcasts',
    icon: 'broadcast'
  }
})
const broadcastStore = useStore('broadcast')
const funnelStore = useStore('funnel')
const metaStore = useStore('meta')
const userStore = useStore('user')
const router = useRouter()

const form = <const>{
  campaign: {
    description: 'Campanha',
    $ref: 'campaign',
    indexes: ['name'],
    isReference: true,
    isFile: false,
    referencedCollection: 'campaign',
  },
  assign_others: {
    description: 'Atribuir para outros atendentes',
    type: 'boolean',
    default: false,
  },
  attendants: {
    description: 'Atendentes',
    type: 'array',
    items: {
      $ref: 'user',
      indexes: ['name', 'email'],
    },
    isReference: true,
    isFile: false,
    referencedCollection: 'user',
  }
}

const formData = reactive<any>({
  assign_others: false,
  attendants: []
})

const formLayout = <const>{
  fields: {
    attendants: {
      if: {
        operator: 'equal',
        term1: 'assign_others',
        term2: true,
      },
    },
  }
}

onMounted(() => {
  if (!userStore.currentUser.current_organization || (typeof userStore.currentUser.current_organization == "object" && Object.keys(userStore.currentUser.current_organization).length == 0)) {
    router.push(`/dashboard`)
  }
})

async function create() {
  await funnelStore.$actions.insert({
    what: {
      current_attendant: 0,
      attendants: [],
      messages: [],
      active: true,
    }
  })

  await broadcastStore.$actions.insert({
    what: {
      ...broadcastStore.item,
      funnel: funnelStore.item._id,
      csv: null
    },
  })

  broadcastStore.$actions.closeModal()

  if (broadcastStore.item._id) {
    router.push(`/creation/broadcast/${broadcastStore.item._id}`)
  }
}

async function createTask() {
  const { error } = await aeria.broadcast.createTask.POST({
    broadcastId: broadcastStore.item._id,
    ...formData
  })

  if (error) {
    metaStore.$actions.spawnModal({
      title: "Ops...",
      body: error.message || error.code
    })
    return
  }

  broadcastStore.$actions.closeTaskModal()
  metaStore.$actions.spawnToast({
    text: 'A tarefa criada com sucesso',
  })
}

function openModal() {
  broadcastStore.$actions.clearItem()
  broadcastStore.$actions.openModal()
}
</script>

<template>
  <aeria-crud collection="broadcast">
    <template #actions>
      <aeria-button
        icon="plus"
        @click="openModal"
      >
        Adicionar
      </aeria-button>
    </template>
  </aeria-crud>

  <aeria-panel
    close-hint
    fixed-right
    title="Broadcast"
    v-model="broadcastStore.modal"
    @overlay-click="() => broadcastStore.$actions.closeModal()"
  >
    <aeria-form
      class="tw-mt-4"
      collection="broadcast"
      :form="broadcastStore.$actions.useProperties(['name'])"
      :layout="broadcastStore.rawDescription.formLayout"
      v-model="broadcastStore.item"
    ></aeria-form>

    <template #footer>
      <aeria-button
        variant="transparent"
        @click="() => broadcastStore.$actions.closeModal()"
      >
        Cancelar
      </aeria-button>
      <aeria-button
        large
        @click="create"
      >Salvar</aeria-button>
    </template>
  </aeria-panel>

  <aeria-panel
    title="Criar tarefas"
    fixed-right
    v-model="broadcastStore.taskModal"
    @overlay-click="() => broadcastStore.$actions.closeTaskModal()"
    :key="broadcastStore.item?._id"
  >
    <aeria-form
      :form="form"
      :layout="formLayout"
      v-model="formData"
    >
    </aeria-form>

    O arquivo csv deve seguir o seguinte formato:
    <div class="aeria-surface-alt tw-p-2 tw-rounded-md">
      phone,name<br />
      {{ '<ddd><número do telefone>,<nome do cliente>' }}<br />
      ...<br />
      {{ '<ddd><número do telefone>,<nome do cliente>' }}<br />
    </div>
    <template #footer>
      <aeria-button
        variant="transparent"
        @click="() => broadcastStore.$actions.closeTaskModal()"
      >
        Cancelar
      </aeria-button>
      <aeria-button large @click="createTask">Criar</aeria-button>
    </template>
  </aeria-panel>
</template>

import { registerStore, createCollectionStore } from 'aeria-ui'
import { isEdge, isNode } from '@vue-flow/core'
import { MarkerType, Position } from '@vue-flow/core'

export type Node = {
  id: string;
  label: string;
  type: string;
  sourcePosition: Position;
  targetPosition: Position;
  data: object;
  position: {
    x: number;
    y: number;
  };
}

export type Edge = {
  id: string;
  target: string;
  source: string;
  data: any;
  markerEnd: MarkerType;
  type: string;
}

type Message = any

type Graph = Record<string, Array<string>>

const makeNode = (message: Message, labelIdx: number = 0): Node => ({
  id: message._id,
  label: 'Mensagem #' + labelIdx,
  // label: message.label,
  type: 'custom',
  sourcePosition: Position.Right,
  targetPosition: Position.Left,
  data: {
    ...message,
  },
  position: {
    x: message.position_x ?? 5,
    y: message.position_y ?? 5,
  },
})

const makeEdge = (
  source: string,
  target: string,
  answers = [],
  tags = [],
): Edge => ({
  id: `e-${source}-${target}`,
  target,
  source,
  data: {
    answers,
    tags,
  },
  markerEnd: MarkerType.ArrowClosed,
  type: 'custom',
})

const hasCycle = (graph: Graph) => {
  const visited: Record<string, 'white' | 'gray' | 'black'> = {}
  const vertices = Object.keys(graph)
  const hasCycleDFS = (vertex: string): boolean => {
    visited[vertex] = 'gray'
    const neighbors = graph[vertex]
    for (const neighbor of neighbors) {
      if (visited[neighbor] === 'gray') {
        return true
      } else if (visited[neighbor] !== 'black' && hasCycleDFS(neighbor)) {
        return true
      }
    }
    visited[vertex] = 'black'
    return false
  }

  for (let i = 0; i < vertices.length; i++) {
    /* eslint-disable */
    if (!visited[i] && hasCycleDFS(vertices[i])) {
      return true;
    }
  }

  return false;
};

const flow = registerStore((context) => {
  const state = reactive({
    nodeIdx: 1,
    flow: [] as Array<Node | Edge>,
    graph: {} as Graph,
  });

  return createCollectionStore(
    {
      $id: "flow",
      state,
      actions: (state) => ({
        clear: () => (state.flow = []),

        getLastNode() {
          return state.flow.slice(0).reverse().find(isNode)
        },

        addNode(node: Node, auto=false) {
          let lastNode = null
          if (auto) {
            lastNode = this.getLastNode() as Node
            node.position = {
              x: (lastNode?.position.x ?? 0) + 500,
              y: (lastNode?.position.y ?? 0),
            }
          }

          state.nodeIdx += 1
          if (state.flow.find((e) => e.id === node.id)) {
            return;
          }

          state.flow.push(node);
          /* eslint-disable */
          if (!state.graph[node.id]) {
            state.graph[node.id] = [];
          }

          if (auto && lastNode) {
            const edge = makeEdge(lastNode.id, node.id)
            this.addEdge(edge)
          }
        },

        addEdge(edge: Edge) {
          if (state.flow.find((e) => e.id === edge.id)) {
            return;
          }

          state.flow.push(edge);

          const { source, target } = edge;
          /* eslint-disable */
          if (!state.graph[source]) {
            state.graph[source] = [];
          }

          if (!state.graph[target]) {
            state.graph[target] = [];
          }

          state.graph[source].push(target);

          if (hasCycle(state.graph)) {
            this.removeEdgeById(edge.id);
          }
        },

        updateAnswer(id: string, answers: Array<string>, tags: Array<string>) {
          for (let index = 0; index < state.flow.length; index++) {
            if (state.flow[index].id === id) {
              state.flow[index].data.answers = answers;
              state.flow[index].data.tags = tags;
            }
          }
        },

        updatePosition(id: string, position: any) {
          for (let index = 0; index < state.flow.length; index++) {
            if (state.flow[index].id === id) {
              (state.flow[index] as any).position = { ...position };
            }
          }
        },

        updateNode(node: Node) {
          state.flow = state.flow.map((x) =>
            x.id === node.id ? { ...node } : x
          );
        },

        removeNodeById(id: string) {
          state.nodeIdx -= 1
          state.flow = state.flow.filter((message: any) => message.id !== id);
          delete state.graph[id];
          state.flow = state.flow.filter((edge: any) => {
            if (isEdge(edge)) {
              if (edge.target === id) {
                if (state.graph[edge.source]) {
                  state.graph[edge.source] = state.graph[edge.source].filter(
                    (e) => e !== id
                  );
                }
                return false;
              }
            }
            return true;
          });
        },

        removeEdgeById(id: string) {
          state.flow = state.flow.filter((edge: any) => {
            if (isEdge(edge)) {
              if (edge.id === id) {
                state.graph[edge.source] = state.graph[edge.source].filter(
                  (e) => e !== edge.target
                );
                return false;
              }
            }

            return true;
          });
        },

        getEdgesBySource(id: string) {
          return state.flow.filter(
            (edge) => isEdge(edge) && edge.source === id
          );
        },

        importMessages(messages: Array<Message>) {
          const nodes = messages.map((message, index) => makeNode(message, index + 1));
          const targets = messages
            .filter((message) => message.next_messages.length > 0)
            .map((message) =>
              message.next_messages.map((m: any) =>
                makeEdge(message._id, m.target, m.answers, m.tags)
              )
            );

          state.flow = [];
          nodes.forEach((node) => this.addNode(node));
          targets.forEach((edges) =>
            edges.forEach((edge: any) => this.addEdge(edge))
          );
        },

        exportMessages(): Array<Message> {
          const messages = state.flow.filter(isNode).map((node: any) => {
            const message = {
              ...node.data,
              _id: node.id,
              label: node.label,
              position_x: node.position.x,
              position_y: node.position.y,
              next_messages: [],
            };

            const edges = this.getEdgesBySource(node.id);
            if (edges.length > 0) {
              return {
                ...message,
                next_messages: edges.map((e: any) => ({
                  answers: e.data.answers,
                  tags: e.data.tags,
                  target: e.target,
                })),
              };
            }
            return message;
          });
          return messages;
        },
      }),
    },
    context
  );
});

export { makeEdge, makeNode, flow };

import _definePage_default_0 from '/opt/application/web/src/pages/index.vue?definePage&vue&lang.tsx'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/opt/application/web/src/pages/creation/broadcast/[id].vue?definePage&vue&lang.tsx'
import _definePage_default_3 from '/opt/application/web/src/pages/creation/external-messages.vue?definePage&vue&lang.tsx'
import _definePage_default_4 from '/opt/application/web/src/pages/creation/funnel.vue?definePage&vue&lang.tsx'
import _definePage_default_5 from '/opt/application/web/src/pages/dashboard/index.vue?definePage&vue&lang.tsx'
import _definePage_default_6 from '/opt/application/web/src/pages/dashboard/attendants.vue?definePage&vue&lang.tsx'
import _definePage_default_7 from '/opt/application/web/src/pages/dashboard/broadcast/index.vue?definePage&vue&lang.tsx'
import _definePage_default_8 from '/opt/application/web/src/pages/dashboard/campaign.vue?definePage&vue&lang.tsx'
import _definePage_default_9 from '/opt/application/web/src/pages/dashboard/chat.vue?definePage&vue&lang.tsx'
import _definePage_default_10 from '/opt/application/web/src/pages/dashboard/chats.vue?definePage&vue&lang.tsx'
import _definePage_default_11 from '/opt/application/web/src/pages/dashboard/integration.vue?definePage&vue&lang.tsx'
import _definePage_default_12 from '/opt/application/web/src/pages/dashboard/my-organization/index.vue?definePage&vue&lang.tsx'
import _definePage_default_13 from '/opt/application/web/src/pages/dashboard/order.vue?definePage&vue&lang.tsx'
import _definePage_default_14 from '/opt/application/web/src/pages/dashboard/organizations.vue?definePage&vue&lang.tsx'
import _definePage_default_15 from '/opt/application/web/src/pages/dashboard/settings/index.vue?definePage&vue&lang.tsx'
import _definePage_topbar_16 from '/opt/application/web/src/pages/dashboard/settings/index@topbar.vue?definePage&vue&lang.tsx'
import _definePage_default_17 from '/opt/application/web/src/pages/dashboard/subscriptions.vue?definePage&vue&lang.tsx'
import _definePage_default_18 from '/opt/application/web/src/pages/dashboard/support.vue?definePage&vue&lang.tsx'
import _definePage_default_19 from '/opt/application/web/src/pages/dashboard/task.vue?definePage&vue&lang.tsx'
import _definePage_default_20 from '/opt/application/web/src/pages/dashboard/user/index.vue?definePage&vue&lang.tsx'
import _definePage_default_21 from '/opt/application/web/src/pages/dashboard/user/[id].vue?definePage&vue&lang.tsx'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: '/',
    component: () => import('/opt/application/web/src/pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  {
    path: '/creation',
    name: '/creation',
    component: () => import('/opt/application/web/src/pages/creation.vue'),
    children: [
      {
        path: 'broadcast',
        /* internal name: '/creation/broadcast' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: ':id',
            name: '/creation/broadcast/[id]',
            component: () => import('/opt/application/web/src/pages/creation/broadcast/[id].vue'),
            /* no children */
          },
  _definePage_default_2
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'external-messages',
        name: '/creation/external-messages',
        component: () => import('/opt/application/web/src/pages/creation/external-messages.vue'),
        /* no children */
      },
  _definePage_default_3
  ),
  _mergeRouteRecord(
      {
        path: 'funnel',
        name: '/creation/funnel',
        component: () => import('/opt/application/web/src/pages/creation/funnel.vue'),
        /* no children */
      },
  _definePage_default_4
  )
    ],
  },
  {
    path: '/dashboard',
    name: '/dashboard',
    component: () => import('/opt/application/web/src/pages/dashboard.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: '/dashboard/',
        component: () => import('/opt/application/web/src/pages/dashboard/index.vue'),
        /* no children */
      },
  _definePage_default_5
  ),
  _mergeRouteRecord(
      {
        path: 'attendants',
        name: '/dashboard/attendants',
        component: () => import('/opt/application/web/src/pages/dashboard/attendants.vue'),
        /* no children */
      },
  _definePage_default_6
  ),
      {
        path: 'broadcast',
        /* internal name: '/dashboard/broadcast' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/dashboard/broadcast/',
            component: () => import('/opt/application/web/src/pages/dashboard/broadcast/index.vue'),
            /* no children */
          },
  _definePage_default_7
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'campaign',
        name: '/dashboard/campaign',
        component: () => import('/opt/application/web/src/pages/dashboard/campaign.vue'),
        /* no children */
      },
  _definePage_default_8
  ),
  _mergeRouteRecord(
      {
        path: 'chat',
        name: '/dashboard/chat',
        component: () => import('/opt/application/web/src/pages/dashboard/chat.vue'),
        /* no children */
      },
  _definePage_default_9
  ),
  _mergeRouteRecord(
      {
        path: 'chats',
        name: '/dashboard/chats',
        component: () => import('/opt/application/web/src/pages/dashboard/chats.vue'),
        /* no children */
      },
  _definePage_default_10
  ),
  _mergeRouteRecord(
      {
        path: 'integration',
        name: '/dashboard/integration',
        component: () => import('/opt/application/web/src/pages/dashboard/integration.vue'),
        /* no children */
      },
  _definePage_default_11
  ),
      {
        path: 'my-organization',
        /* internal name: '/dashboard/my-organization' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/dashboard/my-organization/',
            component: () => import('/opt/application/web/src/pages/dashboard/my-organization/index.vue'),
            /* no children */
          },
  _definePage_default_12
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'order',
        name: '/dashboard/order',
        component: () => import('/opt/application/web/src/pages/dashboard/order.vue'),
        /* no children */
      },
  _definePage_default_13
  ),
  _mergeRouteRecord(
      {
        path: 'organizations',
        name: '/dashboard/organizations',
        component: () => import('/opt/application/web/src/pages/dashboard/organizations.vue'),
        /* no children */
      },
  _definePage_default_14
  ),
      {
        path: 'settings',
        /* internal name: '/dashboard/settings' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/dashboard/settings/',
            components: {
              'default': () => import('/opt/application/web/src/pages/dashboard/settings/index.vue'),
              'topbar': () => import('/opt/application/web/src/pages/dashboard/settings/index@topbar.vue')
            },
            /* no children */
          },
  _definePage_default_15,
_definePage_topbar_16
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'subscriptions',
        name: '/dashboard/subscriptions',
        component: () => import('/opt/application/web/src/pages/dashboard/subscriptions.vue'),
        /* no children */
      },
  _definePage_default_17
  ),
  _mergeRouteRecord(
      {
        path: 'support',
        name: '/dashboard/support',
        component: () => import('/opt/application/web/src/pages/dashboard/support.vue'),
        /* no children */
      },
  _definePage_default_18
  ),
  _mergeRouteRecord(
      {
        path: 'task',
        name: '/dashboard/task',
        component: () => import('/opt/application/web/src/pages/dashboard/task.vue'),
        /* no children */
      },
  _definePage_default_19
  ),
      {
        path: 'user',
        /* internal name: '/dashboard/user' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/dashboard/user/',
            component: () => import('/opt/application/web/src/pages/dashboard/user/index.vue'),
            /* no children */
          },
  _definePage_default_20
  ),
  _mergeRouteRecord(
          {
            path: ':id',
            name: '/dashboard/user/[id]',
            component: () => import('/opt/application/web/src/pages/dashboard/user/[id].vue'),
            /* no children */
          },
  _definePage_default_21
  )
        ],
      }
    ],
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}


<script setup lang="ts">
import { useRoute } from "vue-router";
import { condenseItem } from "aeria-ui";
import MessageSent from "../../components/chat/message-sent.vue";
import ChatMessage from "../../components/chat/chat-message.vue";
import MessageReceived from "../../components/chat/message-received.vue";
import { formatDate, notNull, formatDateTime } from "../../helpers";

definePage({
  meta: {
    title: "Conversa",
    icon: "chat",
  },
});

const route = useRoute();

const socket = new WebSocket(import.meta.env.VITE_WS_URL);
const userStore = useStore("user");
const metaStore = useStore("meta");
const macroStore = useStore("macro");
const customerStore = useStore("customer");
const funnelExecutionStore = useStore("funnelExecution");
const orderStore = useStore("order");

const messages = ref<Array<any>>([]);
const textMesage = ref("");
const macroPanel = ref(true);
const macroModal = ref(false);
const orderModal = ref(false);
const contactModal = ref(false);
const chatFinished = ref(true);
const orders = ref<typeof orderStore.item[]>([]);
const chatRef = ref();
const chatInfo = ref({
  attendant: {
    name: "",
    status: "",
    phone: "",
    picture: "",
  },
  customer: {
    name: "",
    picture: "",
    phone: "",
    status: "",
  },
});

const attendantId = userStore.currentUser._id;

onMounted(async () => {
  await fetchMacros();

  try {
    await funnelExecutionStore.$actions.get({
      filters: {
        _id: route.query.e,
      },
    });

    const { is_finished } =
      await aeria.funnelExecution.chatIsFinished.POST({
        chatId: funnelExecutionStore.item.customer_phone,
      });
    chatFinished.value = is_finished;

    const { error: getWppMessagesError, result } = await aeria.macro.getWppMessages.POST({
      attendantId: funnelExecutionStore.item.attendant._id,
      chatId: funnelExecutionStore.item.customer_phone,
    });

    if(getWppMessagesError) {
      metaStore.$actions.spawnModal({
        title: "Ops...",
        body: getWppMessagesError.message ?? getWppMessagesError.code
      })
    }

    messages.value = result;

    const { error: getChatInfoError, result: getChatInfoResult } = await aeria.macro.getChatInfo.POST({
      attendantId: funnelExecutionStore.item.attendant._id,
      chatId: funnelExecutionStore.item.customer_phone,
    });

    if (getChatInfoError) {
      metaStore.$actions.spawnModal({
        title: "Ops...",
        body: getChatInfoError.message ?? getChatInfoError.code
      })
    }

    chatInfo.value = getChatInfoResult

    if (route.query.customerId) {
      const result = await aeria.customer.getOrders.POST({
        customerId: route.query.customerId,
      });
      orders.value = result.data;
    }

    socket.send(
      JSON.stringify({
        type: "MSG_MACRO_CONN",
        data: {
          attendantId: funnelExecutionStore.item.attendant._id,
          chatId: funnelExecutionStore.item.customer_phone,
        },
      })
    );
  } catch (err: any) {
    metaStore.$actions.spawnModal({
      title: "Ops",
      body: err.message,
    });
  }

  scrolling();
});

const scrolling = () =>
  nextTick(() => {
    chatRef.value.scrollTop = chatRef.value?.scrollHeight;
  });

async function fetchMacros() {
  await macroStore.$actions.getAll({
    filters: {
      owner: attendantId,
    },
  });
}

async function handleSaveMacro() {
  if (!notNull(macroStore.item.name)) {
    metaStore.$actions.spawnModal({
      title: "Ops",
      body: 'O campo "nome" é obrigatório.',
    });
    return false;
  }

  if (
    !notNull(macroStore.item.audio) &&
    !notNull(macroStore.item.image) &&
    !notNull(macroStore.item.text)
  ) {
    metaStore.$actions.spawnModal({
      title: "Ops",
      body: "Preencha o conteudo da mensagem.",
    });
    return false;
  }

  await macroStore.$actions.insert({
    what: condenseItem({
      ...macroStore.item,
      owner: attendantId,
    }),
  });
  macroModal.value = false;
}

function openMacro() {
  macroStore.$actions.clearItem();
  macroModal.value = true;
}

function openEditMacro(message: any) {
  macroStore.$actions.setItem(message);
  macroModal.value = true;
}

function sendMessage(message: any) {
  socket.send(
    JSON.stringify({
      type: "MSG_MACRO_SEND_MESSAGE",
      data: {
        attendantId: funnelExecutionStore.item.attendant._id,
        chatId: funnelExecutionStore.item.customer_phone,
        message,
      },
    })
  );
}

function sendTextMessage() {
  sendMessage({
    type: "text",
    data: textMesage.value,
  });
  textMesage.value = "";
}

function goToWppWeb(customer: { phone: any; name: any }) {
  const link = `https://api.whatsapp.com/send?phone=+${customer.phone}&text=Olá ${customer.name}`;
  window.open(link, "_blank");
}

function makeMacroActions(macro: any) {
  return [
    {
      label: "Enviar",
      icon: "chat-circle",
      click: () =>
        sendMessage({
          type: macro.type,
          data: macro[macro.type],
        }),
    },
    {
      label: "Editar",
      icon: "pencil-simple",
      click: () => openEditMacro(macro),
    },
  ];
}

function createMessageGroup(messages: Array<any>) {
  const groups = {} as any;
  for (const message of messages) {
    const date = message.timestemp.split("T")[0];
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(message);
  }

  return groups;
}

socket.addEventListener("message", (evt) => {
  const event = JSON.parse(evt.data);

  if (event.type == "message") {
    messages.value.push(event.data);
  } else if (event.type == "error") {
    metaStore.$actions.spawnModal({
      title: "Ops",
      body: event.data,
    });
  }

  scrolling();
});
</script>

<template>
  <div class="tw-w-full tw-rounded-t-xl tw-overflow-hidden tw-border">
    <div
      class="tw-flex tw-gap-2 tw-items-center tw-justify-between aeria-surface-alt tw-p-4"
    >
      <div class="tw-flex tw-items-center">
        <aeria-icon
          v-clickable
          reactive
          icon="list"
          @click="macroPanel = !macroPanel"
        ></aeria-icon>

        <div class="tw-flex tw-items-center tw-space-x-3 tw-ml-3">
          <contact-avatar
            :info="chatInfo.customer"
            @click="contactModal = true"
          />

          <p>
            {{ route.query.name }}
          </p>

          <contact-info
            v-model="contactModal"
            :info="chatInfo.customer"
            @on-close="contactModal = false"
          />
        </div>
      </div>
      <div class="tw-flex tw-space-x-2">
        <aeria-button
          v-if="orders.length > 0"
          icon="bag"
          variant="transparent"
          @click="orderModal = true"
        >
          Últimos pedidos
        </aeria-button>

        <!-- <aeria-button
          v-if="!chatFinished"
          icon="check"
          variant="transparent"
          @click="() => finishChat(funnelExecutionStore.item.customer_phone)"
        >
          Finalizar atendimento
        </aeria-button>
        <aeria-button
          v-else
          icon="check"
          variant="transparent"
          @click="() => reactiveChat(funnelExecutionStore.item.customer_phone)"
        >
          Reabrir atendimento
        </aeria-button> -->

        <aeria-button
          icon="whatsapp-logo"
          @click="
            () =>
              goToWppWeb({
                phone: funnelExecutionStore.item.customer_phone,
                name: funnelExecutionStore.item.customer_name,
              })
          "
        >
          Abrir no WhatsApp Web
        </aeria-button>
      </div>
    </div>

    <div class="tw-flex tw-h-[70vh] squared-bg">
      <div
        v-if="macroPanel"
        class="tw-flex tw-flex-col tw-w-[20rem] aeria-surface tw-border-r tw-p-4"
      >
        <div class="tw-flex tw-flex-wrap tw-gap-2">
          <aeria-context-menu
            v-for="macro in macroStore.items"
            :actions="makeMacroActions(macro)"
          >
            <a
              v-clickable
              class="aeria-surface-alt tw-p-2 tw-text-[9pt] tw-rounded"
            >
              {{ macro.name }}
            </a>
          </aeria-context-menu>
        </div>

        <div class="tw-mt-auto">
          <aeria-button @click="openMacro" icon="plus">
            Adicionar
          </aeria-button>
        </div>
      </div>

      <div class="tw-flex tw-flex-col tw-w-full">
        <div
          ref="chatRef"
          class="tw-flex tw-flex-col tw-flex-1 tw-gap-4 tw-overflow-auto tw-p-6"
        >
          <template
            v-for="(group, date) in createMessageGroup(messages)"
            :key="date"
          >
            <div class="tw-flex tw-justify-center">
              <div
                class="tw-px-2 tw-px-3 tw-rounded-full aeria-surface tw-gap-2 tw-text-xs"
              >
                {{ formatDate(date) }}
              </div>
            </div>

            <div v-for="message in group" :key="messages.length">
              <message-sent
                :timestemp="message.timestemp"
                :info="chatInfo.attendant"
                v-if="message.fromMe"
              >
                <chat-message :message="message" />
              </message-sent>
              <message-received
                :timestemp="message.timestemp"
                :info="chatInfo.customer"
                v-else
              >
                <chat-message :message="message" />
              </message-received>
            </div>
          </template>
        </div>

        <div
          class="aeria-surface-alt tw-flex tw-items-center tw-gap-6 tw-p-6 tw-border-t"
        >
          <aeria-icon
            icon="paper-plane-right"
            v-clickable
            @click="sendTextMessage"
          ></aeria-icon>

          <aeria-input
            v-model="textMesage"
            :property="{
              readOnly: false,
              placeholder: 'Insira a sua mensagem...',
            }"
            style="
              width: 100%;
              --input-background-color: var(--theme-background-color);
              --input-border-color: var(--theme-border-color);
            "
          />
        </div>
      </div>
    </div>
  </div>

  <aeria-panel
    float
    close-hint
    title="Adicionar macro"
    v-model="macroModal"
    @overlay-click="() => (macroModal = false)"
  >
    <aeria-form
      collection="macro"
      :form="
        macroStore.$actions.useProperties([
          'name',
          'type',
          'text',
          'image',
          'audio',
        ])
      "
      v-model="macroStore.item"
      :layout="macroStore.description.formLayout"
    />

    <template #footer>
      <aeria-button large @click="handleSaveMacro"> Salvar </aeria-button>
    </template>
  </aeria-panel>

  <aeria-panel
    float
    close-hint
    title="Últimas pedidos"
    v-model="orderModal"
    @overlay-click="() => (orderModal = false)"
  >
    <aeria-table :key="orders.length">
      <template #thead>
        <tr>
          <th>ID local</th>
          <th>Produtos</th>
          <th>Status</th>
          <th>Atualizado em</th>
        </tr>
      </template>
      <template #tbody>
        <tr v-for="order in orders" :key="order._id.toString()">
          <td>
            <div class="tw-flex tw-flex-col tw-gap-1">
              <div>{{ order.local_id }}</div>
              <div class="tw-text-[8.5pt] tw-opacity-60">
                {{ order.token }}
              </div>
            </div>
          </td>
          <td>
            <span class="tw-flex tw-items-center tw-gap-1">
              <extra-icon :name="order.integration?.platform"></extra-icon>
              <div>
                {{ order.products.map((product) => product.name).join(", ") }}
              </div>
            </span>
          </td>
          <td>
            <status-badge
              :color="orderStore.$actions.getComputedColor(order.status as string)"
            >
              {{ t(order.status || "-") }}
            </status-badge>
          </td>
          <td>
            {{ formatDateTime(order.updated_at) }}
          </td>
        </tr>
      </template>
    </aeria-table>
  </aeria-panel>
</template>

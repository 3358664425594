<script setup lang="ts">
import { formatCurrency } from "../../helpers";

definePage({
  meta: {
    title: "Dashboard",
    icon: "gauge",
  },
});

const result = ref({
  totalMessagesSent: 0,
  conversionStats: [],
  income: [],
  commissions: 0,
  invoicing: 0,
  conversion: {
    paid: 0,
    pending: 0,
  },
});

onMounted(async () => {
  const data = await aeria.dashboard.summary.GET()
  result.value = data
});
</script>

<template>
  <div class="grid">
    <card-info
      title="Faturamento"
      :data="formatCurrency(result.invoicing)"
      style="grid-area: a"
    />

    <card-info
      title="Comissões"
      :data="formatCurrency(result.commissions)"
      style="grid-area: b"
    />

    <card-info
      title="Mensagens Enviadas"
      :data="result.totalMessagesSent"
      style="grid-area: c"
    />

    <div
      class="aeria-surface-alt tw-text-gray-600 tw-p-4 tw-rounded-xl"
      style="grid-area: d"
    >
      <chart-conversion :data="result.conversion" />
    </div>

    <div
      class="aeria-surface-alt tw-text-gray-600 tw-p-4 tw-rounded-xl"
      style="grid-area: e"
    >
      <chart-income :data="result.income" />
    </div>

    <card-events
      :data="result.conversionStats.map((cv: any) =>
        ({...cv, percent: `${cv.percent ?? 0}%`, value: formatCurrency(cv.value)}))
      "
      style="grid-area: f"
    />

    <aeria-table
      :columns="{
        name: {},
        quantity: {},
        percent: {},
        value: {},
      }"
      :rows="result.conversionStats.map((cv: any) =>
        ({...cv, percent: `${cv.percent ?? 0}%`, value: formatCurrency(cv.value)}))
      "
      style="grid-area: f"
    >
      <template #row-name="{ row, column }">
        {{ t(row[column]) }}
      </template>
    </aeria-table>
  </div>
</template>

<style scoped lang="less">
.grid {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media screen and (min-width: 600px) {
    display: grid;
    gap: 1.6rem;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
      "a b c"
      "d e e"
      "f f f";
  }
}
</style>

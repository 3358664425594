<script setup lang="ts">
import type { CollectionItemWithId } from '@aeriajs/types'
import { getMyOrganization } from '../../helpers'
import {
  hasActiveSubscription
} from '../../../../api/src/modules/subscription/hasActiveSubscription'
import { AeriaButton } from 'aeria-ui'

definePage({
  meta: {
    title: 'Assinaturas',
    icon: 'currency-dollar'
  }
})

const userStore = useStore('user')
const router = useRouter()

const status = ref<'active' | 'inactive'>('active')
const org = ref({} as CollectionItemWithId<'organization'>)

onMounted(async () => {
  const { result: currentOrganization } = await getMyOrganization(userStore.currentUser)
  if (!currentOrganization?.me.roles) {
    router.push('/dashboad')
    return
  }

  if (!currentOrganization.me.roles.includes('manager')) {
    router.push('/dashboad')
    return
  }

  status.value = hasActiveSubscription(currentOrganization)
    ? 'active'
    : 'inactive'

  org.value = currentOrganization
})

const onAcquirePlan = () => window.open('https://nullam.com.br/#plan')
</script>

<template>
  <div>
    <div class="
      tw-flex
      tw-items-center
      tw-gap-2
    ">
      <h2>Plano atual</h2>

      <span
        :class="`
          ${status === 'active' ? 'tw-bg-green-100' : 'tw-bg-red-100'}
          ${status === 'active' ? 'tw-text-green-800' : 'tw-text-red-800'}
          tw-text-xs
          tw-font-medium
          tw-mr-2
          tw-px-2.5
          tw-py-0.5
          tw-rounded
        `"
      >
        {{ status === 'active' ? 'Ativo' : 'Inativo' }}
      </span>
    </div>
    <p>
      Você está utilizando o plano básico.
    </p>
    <aeria-button
      class="tw-mt-10"
      v-if="status === 'inactive'"
      variant="primary"
      icon="arrow-square-up-right"
      @click="onAcquirePlan"
      >
      Adquira um plano
    </aeria-button>
  </div>

  <div>
    <h2>Histórico de assinaturas</h2>
    <subscriptions-table v-if="(org.subscriptions?.length || 0) > 0" :subscriptions="org.subscriptions" />
    <p v-else>Nenhuma assinatura encontrada.</p>
  </div>
</template>

<script setup lang="ts">
const router = useRouter()

definePage({
  meta: {
    title: "Usuários",
    icon: "user-circle"
  }
})

async function handler(event: any) {
  if (event.name === "loginAs") {
    const { result } = await aeria.user.loginAs.POST({
      _id: event.params._id
    })

    const auth = JSON.parse(<string>localStorage.getItem('aeria:auth'))

    const previous = JSON.parse(localStorage.getItem('aeria:previous-auth') ?? '[]') as Array<any>
    previous.push(auth)

    localStorage.setItem('aeria:previous-auth', JSON.stringify(previous))
    localStorage.setItem('aeria:auth', JSON.stringify(result))

    router.push('/dashboard')
  }
}
</script>

<template>
  <aeria-crud collection="user" @ui-event="handler" />
</template>

<script setup lang="ts">
import Funnel from "../../components/funnel/funnel.vue";
import { condenseItem, STORE_ID } from "aeria-ui";

definePage({
  meta: {
    title: "Funil",
    icon: "funnel",
  },
});

const funnelStore = useStore("funnel");
const metaStore = useStore("meta");
const userStore = useStore("user");

const copyPanel = ref(false);
const formData = reactive({
  product: null,
  product_plan: null,
  event: null,
  target: null,
} as {
  product: null | any;
  product_plan: null | any;
  event: null | any;
  target: null | any;
});

provide(STORE_ID, "funnel");

onMounted(() => {
  funnelStore.$actions.clearItem();
});

watch(formData, async (values) => {
  if (values.target == "product") {
    if (values.product && values.product._id && values.event) {
      await changeFlow(values.event, {
        productId: values.product._id,
      });
    }
  } else {
    if (values.product_plan && values.product_plan._id && values.event) {
      await changeFlow(values.event, {
        productPlanId: values.product_plan._id,
      });
    }
  }
});

async function changeFlow(
  event: typeof funnelStore.item.event,
  data: { 
    productId?: string;
    productPlanId?: string;
  }) {
  const { error, result } = await aeria.funnel.get.POST({
    filters: {
      event,
      product: data.productId,
      product_plan: data.productPlanId,
      organization: userStore.currentUser.current_organization
    }
  });

  if (error) {
    funnelStore.$actions.setItem(formData);
    return
  }

  funnelStore.$actions.setItem(result);
}

const handleSaveForm = async (messages: Array<any>) => {
  if (funnelStore.item.attendants.length > 0) {
    const { result } = await aeria.funnel.updateOrCreate.POST({
      funnel: {
        ...condenseItem({ ...funnelStore.item, ...formData }),
        messages,
      },
    });

    funnelStore.$actions.setItem(result)

    metaStore.$actions.spawnToast({
      text: "Os dados foram salvos com sucesso",
    });
  } else {
    metaStore.$actions.spawnModal({
      title: "Ops...",
      body: 'O campo "atendentes" é obrigatório',
    });
  }
};

const handleSaveFlow = async (messages: Array<any>) => {
  funnelStore.$actions.setItem({
    ...funnelStore.item, 
    ...formData, 
    messages 
  })
};

const openCopyFunnel = () => {
  if(!funnelStore.item._id) {
    metaStore.$actions.spawnToast({text: "Salve o funil antes de copiá-lo!", icon: 'prohibit'})
    return
  }
  copyPanel.value = true
}
</script>

<template>
  <funnel :title="funnelStore.item.product
      ? funnelStore.item.product.name
      : funnelStore.item.product_plan?.name" 
      :save-disabled="!formData.event || (!formData.product && !formData.product_plan)" 
      @save-form="handleSaveForm" @save-flow="handleSaveFlow">
    <template #actions>
      <aeria-button icon="copy" variant="transparent" :disabled="!funnelStore.item.product?._id && !funnelStore.item.product_plan?._id
    " style="--button-border-color: var(--theme-brand-color-shade-2)" @click="openCopyFunnel">
        Copiar
      </aeria-button>
    </template>

    <template #form>
      <aeria-form collection="funnel" :form="funnelStore.$actions.useProperties([
    'target',
    'product',
    'product_plan',
    'event',
  ])
    " :layout="funnelStore.rawDescription.formLayout" v-model="formData"></aeria-form>

      <aeria-form collection="funnel" class="tw-mt-4" :key="funnelStore.item._id?.toString()" :form="funnelStore.$actions.useProperties([
    'attendants',
    'work_time',
    'start_time',
    'end_time',
    'tags',
  ])
    " :layout="funnelStore.rawDescription.formLayout" v-model="funnelStore.item" />
    </template>
  </funnel>
  <copy-funnel :key="funnelStore.item._id?.toString()" :funnel="funnelStore.item"
    v-model="copyPanel" />
</template>

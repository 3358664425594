import { defineLocale, LocaleMessages } from 'aeria-ui'
import { ExecutionError } from '../../../api/src/modules/task/execute'

const executionErrorPtBr: Record<Lowercase<keyof typeof ExecutionError>, string> = {
    "csvnotfound": "Csv não encontrado",
    "customernotfound": "Cliente não encontrado",
    "funnelnotfound": "Funil não encontrado",
    "ordernotfound": "Pedido não encontrado",
    "tasknotfound": "Tarefa não encontrada",
    "taskpermissionerror": "Você não tem permissão para executar esta tarefa",
    "whatsappdisconnected": "Whatsapp desconectado",  
}

export const ptbr = defineLocale({
    "conversationTag": ["tag de atendimento", "tags de atendimento"],
    "days": "dias",
    "hours": "horas",
    "minutes": "minutos",
    "seconds": "segundos",
    "blue": "azul",
    "purple": "roxo",
    "red": "vermelho",
    "yellow": "amarelo",
    "created": "criado",
    "authorized": "autorizado",
    "waiting_payment": "pendente",
    "paid": "pago",
    "handling_products": "em preparação",
    "on_carriage": "a caminho",
    "delivered": "entregue",
    "cancelled": "cancelado",
    "refused": "recusado",
    "invoiced": "nota fiscal emitida",
    "shipment_exception": "exceção na entrega",
    "in_review": "em revisão",
    "sent": "enviado",
    "pending": "pendente",
    "abort": "Abortar",
    "active": "Ativo",
    "archived": ["arquivada", "arquivadas"],
    "attendent": ["Atendente", "Atendentes"],
    "audio": ["Audio", "Audios"],
    "broadcast": ["Broadcast", "Broadcasts"],
    "cart_reminder": "Carrinho abandonado",
    "complete": ["completa", "completas"],
    "contact": ["contato", "contatos"],
    "created_at": "Criado em",
    "customer": ["cliente", "clientes"],
    "dashboard": "dashboard",
    "new": "novo",
    "exists": "existente",
    "full_name": "Nome Completo",
    "funnel": ["funil", "funis"],
    "funnelExecution": ["execução", "execuções"],
    "image": ["Imagem", "Imagens"],
    "integration": ["Integração", "Integrações"],
    "members_count": "número de membros",
    "inactive": "Inativo",
    "name": "nome",
    "order:paid": "Pedido pago",
    "order:canceled": "Pedido Cancelado",
    "order:pix_created": "Pix criado",
    "order:billet_created": "Boleto gerado",
    "order:billet_expiring": "Boleto expirado",
    "cart:reminder": "Carrinho abandonado",
    "order:created": "Pedido criado",
    "transaction:payment_refused": "Pagamento recusado",
    "organization": ["Organização", "Organizações"],
    "pendingPostback": ["Postback pendente", "Postbacks pendentes"],
    "percent": "porcentagem",
    "picture": "Foto",
    "platform": ["plataforma", "plataformas"],
    "product": ["Produto", "Produtos"],
    "productPlan": ["Plano", "Planos"],
    "quantity": "quantidade",
    "roles": "Papéis",
    "running": "em execução",
    "paused": "pausado",
    "send_anyway": "Enviar de qualquer maneira",
    "setting": ["configuração", "configurações"],
    "start": "início",
    "transaction_payment_refused": "Pagamento recusado",
    "updated_at": "Atualizado em",
    "user": ["Usuário", "Usuários"],
    "value": "valor",
    "waiting_for_message": "aguardando mensagem",
    "waiting_user_answer": "aguardando resposta",
    "waiting_to_start": "aguardando inicio",
    "description": "descrição",
    "color": "cor",
    "all": "Todos",
    "weekly": "Semanalmente",
    "monthly": "Mensalmente",
    "bimonthly": "Bimestralmente",
    "quarterly": "Trimestralmente",
    "semiannually": "Semestralmente",
    "annually": "Anualmente",
    "basic": "Básico",
    "defaulter": "Inadimplente",
    "canceled": "Cancelado",
    "trial_period": "Período de Teste",
    "credit_card": "Cartão de Crédito",
    "debit": "Débito",
    "billet": "Boleto Bancário",
    "pix": "Pix",
    "free_sale": "Grátis",
    "other": "Outro",
    "manager": "Gerente",
    "attendant": "Atendente",
    "accepted": "Aceito",
    "rejected": "Rejeitado",
    "removed": "Removido",
    "members": "Membros",
    "date_min": "Data início",
    "date_max": "Data fim",
    "event": ["Evento", "Eventos"],
    "status": "Status",
    "current_organization": "organização atual",
    "copy_activation_link": "Copiar link de ativação",
    "signout": "Sair",
    "Profile": "Perfil",
    "Edit_profile": "Editar perfil",
    "copy_type_single": "Copiar do funil atual",
    "copy_type_multiple": "Copiar dos funis dos eventos selecionados",
    ...executionErrorPtBr
} as LocaleMessages)

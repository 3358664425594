import { useStore, registerStore, createCollectionStore } from 'aeria-ui'
import type { GetTasks } from '../../../api/src/collections/campaign/getTasks.js'

export const campaign = registerStore((context) =>
  createCollectionStore(
    {
      $id: 'campaign',
      state: {
        modal: false,
        tasks: <Awaited<ReturnType<GetTasks>>["result"]>[],
      },
      actions: (state) => ({
        async viewExecutions(campaign: any) {
          const campaignStore = useStore('campaign', context.manager)

          const { result }: Awaited<ReturnType<GetTasks>> = await aeria.campaign.getTasks.POST({
            campaignId: campaign._id,
          })
          
          state.tasks = result
          state.modal = true
        },
      }),
    },
    context,
  ))

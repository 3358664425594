import { registerStore, createCollectionStore } from 'aeria-ui'

export const order = registerStore((context) => {
  const state = reactive({
    modal: false,
  })

  return createCollectionStore(
    {
      $id: 'order',
      state,
      actions: (state) => ({
        executeAll() {
          state.modal = true
        },
        closeModal() {
          state.modal = false
        },
        getComputedColor(status: string) {
          switch (status) {
            case 'paid':
              return 'green'
            case 'cancelled':
            case 'refused':
              return 'red'
            case 'waiting_payment':
              return 'yellow'
            default:
              return 'blue'
          }
        },
      }),
    },
    context,
  )
})

<script setup lang="ts">
import { getMyOrganization } from '../../helpers'

definePage({
  meta: {
    title: 'Integrações',
    icon: 'link'
  }
})

const router = useRouter()
const metaStore = useStore('meta')
const integrationStore = useStore('integration')
const orderStore = useStore('order')
const userStore = useStore('user')

const spanPanel = ref(false)

const formData = ref({
  integration_id: ''
})

onMounted(async () => {
  const { result: currentOrganization } = await getMyOrganization(userStore.currentUser)

  if (!currentOrganization) {
    metaStore.$actions.spawnModal({
        title: "Ops...",
        body: "Erro ao requisitar organização atual"
      })
    return
  }

  if (!currentOrganization.me.roles) {
    router.push('/dashboard')
    return
  }

  if (!currentOrganization.me.roles.includes('manager')) {
    router.push('/dashboard')
    return
  }
})

const handleUiEvent = async (event: any) => {
  if (event.name === 'getSpan') {
    spanPanel.value = true
    formData.value.integration_id = event.params._id
    await integrationStore.$actions.setItem(event.params)
  } else if (event.name === 'synchronize') {
    metaStore.$actions.spawnToast({
      text: "Isso pode levar alguns segundos"
    })

    const { error, result: products } = await aeria.integration.synchronize.POST({
      _id: event.params._id
    })

    if (error || !products) {
      metaStore.$actions.spawnModal({
        title: 'Ops...',
        body: 'Erro ao sincronizar produtos: ' + (error?.message ?? (error?.code ?? "Desconhecido"))
      })
      return
    }

    await integrationStore.$actions.getAll()
    
    metaStore.$actions.spawnModal({
      title: 'Sucesso',
      body: 'Produtos sincronizados',
    })
  }
}

const getSpan = async () => {
  await aeria.order.getSpan.POST(formData.value)
  metaStore.$actions.spawnModal({
    title: 'Sucesso',
    body: 'Tarefa adicionada à fila'
  })
}
</script>

<template>
  <aeria-crud collection="integration" @ui-event="handleUiEvent">
    <template #row-platform="{ row, column }">
      <div class="
        tw-flex
        tw-items-center
        tw-gap-1
      ">
        <extra-icon :name="row.platform"></extra-icon>
        <div>
          {{ row[column] }}
        </div>
      </div>
    </template>
  </aeria-crud>

  <aeria-panel float close-hint title="Busca retroativa" v-model="spanPanel" @overlay-click="spanPanel = false">
    <header class="tw-mb-12">Selecionado: {{ integrationStore.item.name }}</header>
    <aeria-form v-bind="{
      form: {
        date_min: {
          type: 'string',
          format: 'date'
        },
        date_max: {
          type: 'string',
          format: 'date'
        }
      },
    }" v-model="formData"></aeria-form>
    <template #footer>
      <aeria-button @click="getSpan">Buscar</aeria-button>
    </template>
  </aeria-panel>

</template>

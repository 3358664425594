import type { PackReferences } from 'aeria'
import type { Organization } from '../../collections/organization/description.js'
import { calculateEndDateRecurrent } from './calculateEndDateRecurrent.js'

const checkDate = (date1: Date, date2: Date) => {
  const yearDiff = date1.getUTCFullYear() - date2.getUTCFullYear()
  const monthDiff = date1.getUTCMonth() - date2.getUTCMonth()
  const dayDiff = date1.getUTCDate() - date2.getUTCDate()

  if (yearDiff < 0) {
    return true
  } else if (yearDiff > 0) {
    return false
  }

  if (monthDiff < 0) {
    return true
  } else if (monthDiff > 0) {
    return false
  }

  return dayDiff <= 0
}

export const sortSubscriptions = (subscriptions: Array<any> | undefined) =>
  (subscriptions || []).sort((a, b) =>
    new Date(b.start_date_recurrent).getTime() -
      new Date(a.start_date_recurrent).getTime())

const findLatestSubscription = (subscriptions: Array<any>) =>
  subscriptions.length === 0
    ? null
    : sortSubscriptions(subscriptions)[0]

export const hasActiveSubscription = (organization: PackReferences<Organization> | Organization) => {
  const subscription = findLatestSubscription(organization.subscriptions ?? [])
  if (!subscription) {
    return false
  }

  if (!subscription.start_date_recurrent || !subscription.periodicity) {
    return false
  }

  const endDateRecurrent = calculateEndDateRecurrent(
    subscription.start_date_recurrent,
    subscription.periodicity,
  )

  const currentDate = new Date()
  if (subscription.subscription_status === 'trial_period') {
    return checkDate(currentDate, endDateRecurrent)
  }

  if (subscription.subscription_status === 'active') {
    return checkDate(currentDate, endDateRecurrent)
  }

  return false
}

<script setup lang="ts">
const paths = {
  correios: '/icons/correios.png',
  jadlog: '/icons/jadlog.png',
  totalexpress: '/icons/totalexpress.png',
  loggi: '/icons/loggi.jpg',
  braip: '/icons/braip.svg',
  monetizze: '/icons/monetizze.png',
  perfectpay: '/icons/perfectpay.png',
  yampi: '/icons/yampi.png',
  kiwify: '/icons/kiwify.svg',
  ticto: '/icons/ticto.png',
  eduzz: '/icons/eduzz.png',
  hotmart: '/icons/hotmart.png',
  payt: '/icons/payt.png',
  themart: '/icons/themart.svg',
}

type Props = {
  name: keyof typeof paths
  size?: `${string}rem`
}

const props = withDefaults(defineProps<Props>(), {
  size: '1.4rem'
})
</script>

<template>
  <img
    v-if="name"
    :src="paths[name]"
  />
</template>

<style scoped lang="less">
@image-size: v-bind(size);

img {
  width: @image-size;
  height: @image-size;
  object-fit: contain;
}
</style>

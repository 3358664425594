<script setup lang="ts">
import { getMyOrganization } from "../../../helpers";

definePage({
  meta: {
    title: "Minha organização",
    icon: "buildings",
  },
});

const userStore = useStore("user");
const metaStore = useStore("meta");
const router = useRouter()

const org = ref<any>({});

const isEditMemberVisible = ref(false);
const isNewMemberVisible = ref(false);

const formEditMemberSchemaModel = ref<any>({});
const formNewMemberModel = ref({});

const formEditMemberSchema = <const>{
  status: {
    description: "Status",
    enum: ["active", "inactive"],
  },
  roles: {
    type: "array",
    items: {
      enum: ["manager", "attendant"],
    },
    translate: true,
    uniqueItems: true,
  },
};
const formNewMemberSchema = <const>{
  target: {
    description: "Adicionar a partir de",
    translate: true,
    element: "select",
    enum: ["new", "exists"],
  },
  name: {
    description: "Nome completo",
    type: "string",
  },
  email: {
    description: "Email",
    type: "string",
  },
  roles: {
    type: "array",
    items: {
      enum: ["manager", "attendant"],
    },
    translate: true,
    uniqueItems: true,
  },
};

const formNewMemberLayout = <const>{
  fields: {
    name: {
      if: {
        operator: "equal",
        term1: "target",
        term2: "new",
      },
    },
    email: {
      if: {
        or: [
          {
            operator: "equal",
            term1: "target",
            term2: "new",
          },
          {
            operator: "equal",
            term1: "target",
            term2: "exists",
          },
        ],
      },
    },
  },
};

onMounted(async () => {
  const { result: currentOrganization } = await getMyOrganization(userStore.currentUser);
  org.value = currentOrganization;
});

function makeActionsForMember(member: any) {
  return [
    {
      label: "Editar",
      icon: "pencil",
      click: () => editMember(member),
    },
    {
      label: "Remover da organização",
      icon: "user-minus",
      click: () => removeMember(member),
    },
    {
      label: "Gerar link de login",
      icon: "user-plus",
      click: () => generateLoginLink(member),
    },
  ];
}

async function generateLoginLink(member: any) {
  try {
    const resp = await aeria.organization.generateLoginLink.POST({
      memberId: member.user._id,
    });

    navigator.clipboard.writeText(`${window.location.origin}${resp.uri}`);

    metaStore.$actions.spawnToast({
      text: 'Link copiado',
      icon: 'info',
    })
  } catch (err: any) {
    metaStore.$actions.spawnModal({
      title: "Ops",
      body: err.message,
    });
  }
}

async function removeMember(member: any) {
  await metaStore.$actions.spawnPrompt({
    body: "Você realmente deseja executar esta ação?",
    options: {
      cancel: {
        title: "Cancelar",
        variant: "danger",
      },
      confirm: {
        title: "Sim",
        click: async () => {
          const { error } = await aeria.organization.removeMember.POST({
            memberId: member.user._id,
          });
          if (error) {
            metaStore.$actions.spawnModal({
              title: "Ops",
              body: error.message,
            });
          }

          router.go(0);
        }
      },
    },
  });
}

function editMember(member: any) {
  formEditMemberSchemaModel.value = { ...member };
  isEditMemberVisible.value = true;
}

async function updateMember() {
  if (
    !formEditMemberSchemaModel.value.status ||
    !formEditMemberSchemaModel.value.roles
  ) {
    metaStore.$actions.spawnModal({
      title: "Ops",
      body: "Preencha os dados corretamente.",
    });
    return;
  }

  const { error } = await aeria.organization.updateMember.POST({
    memberId: formEditMemberSchemaModel.value.user._id,
    status: formEditMemberSchemaModel.value.status,
    roles: formEditMemberSchemaModel.value.roles,
  });
  if (error) {
    metaStore.$actions.spawnModal({
      title: "Ops",
      body: error.message,
    });
  }

  router.go(0);
}

async function saveAddNewMember() {
  const { error } = await aeria.organization.addNewMember.POST({
    ...formNewMemberModel.value,
  });

  if (error) {
    metaStore.$actions.spawnModal({
      title: "Ops",
      body: error.message,
    });
    return
  }

  router.go(0);
}
</script>

<template>
  <header class="tw-text-[16pt] tw-mb-4">
    {{ org.name }}
  </header>

  <div>
    <aeria-button @click="isNewMemberVisible = true">
      Adicionar novo membro
    </aeria-button>
  </div>

  <div>
    <h3>Membros</h3>
    <aeria-table>
      <template #thead>
        <tr>
          <th>Nome</th>
          <th>Email</th>
          <th>Papéis</th>
          <th>Status</th>
          <th>Ações</th>
        </tr>
      </template>
      <template #tbody>
        <tr v-for="member in org.members" :key="member._id">
          <td>{{ member.user.name }}</td>
          <td>{{ member.user.email }}</td>
          <td>{{ member.roles.map((role: string) => t(role)).join(", ") }}</td>
          <td>{{ t(member.status) }}</td>
          <td>
            <aeria-context-menu v-if="member.user._id != userStore.currentUser._id &&
      member.user._id != org.owner._id
      " :actions="makeActionsForMember(member)">
              <aeria-icon v-clickable reactive icon="gear"></aeria-icon>
            </aeria-context-menu>
          </td>
        </tr>
      </template>
    </aeria-table>
  </div>

  <!-- modal de editar membro -->
  <aeria-panel close-hint floating v-model="isEditMemberVisible" title="Membro"
    @overlay-click="isEditMemberVisible = false">
    <aeria-form :form="formEditMemberSchema" v-model="formEditMemberSchemaModel"></aeria-form>
    <template #footer>
      <aeria-button variant="transparent" @click="isEditMemberVisible = false">
        Cancelar
      </aeria-button>
      <aeria-button large @click="updateMember">Salvar</aeria-button>
    </template>
  </aeria-panel>

  <!-- modal de novo membro -->
  <aeria-panel fixed-right close-hint v-model="isNewMemberVisible" title="Novo membro"
    @overlay-click="isNewMemberVisible = false">
    <aeria-form :form="formNewMemberSchema" :layout="formNewMemberLayout" v-model="formNewMemberModel">
    </aeria-form>
    <template #footer>
      <aeria-button variant="transparent" @click="isNewMemberVisible = false">
        Cancelar
      </aeria-button>
      <aeria-button large @click="saveAddNewMember">Salvar</aeria-button>
    </template>
  </aeria-panel>
</template>

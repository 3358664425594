<script setup lang="ts">
const props = defineProps<{
  id: string;
}>();

definePage({
  meta: {
    title: "Página do usuário",
    icon: "user-circle"
  },
  props: true
})

const userStore = useStore("user");
const user = computed(() => userStore.item);

await userStore.$actions.get({
  filters: {
    _id: props.id,
  },
});
</script>

<template>
  <div>
    <div class="tw-text-2xl tw-font-bold tw-mb-1">
      {{ user.name }}
    </div>
    <div class="tw-text-sm tw-text-gray-500">
      Membro desde: {{ getRelativeTimeFromNow(new Date(user.created_at)) }}
    </div>
  </div>
</template>

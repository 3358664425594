import { registerStore, createCollectionStore } from 'aeria-ui'

export const organization = registerStore((context) => {
  const state = reactive({
    currentSubscriptions: [],
    modal: false,
  })

  const metaStore = useStore('meta', context.manager)

  return createCollectionStore(
    {
      $id: 'organization',
      state,
      actions: (state, actions) => ({
        showSubscriptions(organization: any) {
          state.currentSubscriptions = organization.subscriptions
          state.modal = true
        },
        async renewLastSubscription({ _id, subscriptions }: { _id: string, subscriptions: any[]| undefined}) {
          if(!subscriptions || subscriptions?.length <= 0) {
            metaStore.$actions.spawnModal({
              title: 'Ops...',
              body: 'Organização não possui nenhuma assinatura'
            })
            return
          }

          const { error }: any = await actions.custom('renewLastSubscription', { _id, subscriptions })

          if (error) {
            metaStore.$actions.spawnModal({
              title: 'Ops...',
              body: error.message || error.code
            })
            return
          }

          metaStore.$actions.spawnToast({
            text: "Assinatura renovada!"
          })
          
          ROUTER.go(0)
        }
      }),
    },
    context,
  )
})

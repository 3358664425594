import { registerStore, createCollectionStore } from 'aeria-ui'

export const funnel = registerStore((context) => {
  const state = reactive({
    modal: false,
    newMessageModal: false,
  })

  return createCollectionStore(
    {
      $id: 'funnel',
      state,
      actions: (state) => ({
        openModal() {
          state.modal = true
        },
        closeModal() {
          state.modal = false
        },
      }),
    },
    context,
  )
})

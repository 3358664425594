import {
  useStore,
  registerStore,
  createCollectionStore,
} from 'aeria-ui'

export const integration = registerStore((context) => {
  return createCollectionStore(
    {
      $id: 'integration',
      state: {},
      actions: () => ({
        copyLink(integration: any) {
          const copyToClipboard = null as any
          const metaStore = useStore('meta', context.manager)

          copyToClipboard(integration.postback_url)
          metaStore.$actions.spawnToast({
            text: 'O link foi copiado com sucesso',
          })
        },
      }),
    },
    context,
  )
})

<script setup lang="ts">
const integrationStore = useStore('integration')
const platforms = integrationStore.description.properties.platform.enum

defineProps<{
  modelValue?: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()
</script>

<template>
  <div>
    <div class="tw-text-xs">
      Selecionado: <b>{{ modelValue }}</b>
    </div>
    <div class="
      tw-flex
      tw-flex-wrap
      tw-gap
      tw-gap-2
    ">
      <aeria-info
        v-for="platform in platforms"
        :key="platform"
        >
        <template #text>
          {{ platform }}
        </template>
        <div
          v-clickable
          :class="`
            tw-border
            tw-rounded
            tw-p-4
            ${platform === modelValue && 'tw-border-blue-100 tw-bg-blue-100'}
          `"
          @click="emit('update:modelValue', platform)"
        >
          <extra-icon
            :name="platform"
            size="1.8rem"
          />
        </div>
      </aeria-info>
    </div>
  </div>
</template>

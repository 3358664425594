import { Result } from "aeria-sdk"

export const getMyOrganization = async (user: any) => {
  const { error, result: organization} = await aeria.organization.get.POST({
    filters: {
      _id: user.current_organization,
    },
  })

  if (error) {
    return Result.error(error)
  }

  const member = organization.members?.find((m: any) => m.user?._id.toString() === user?._id.toString())
  if (!member) {
    return Result.error("Not a member")
  }

  return Result.result({
    ...organization,
    me: member,
  })
}

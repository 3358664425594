type Periodicity =
  | 'weekly'
  | 'monthly'
  | 'bimonthly'
  | 'quarterly'
  | 'semiannually'
  | 'annually'

export const calculateEndDateRecurrent = (
  initialDate: Date | string,
  periodicity: Periodicity,
) => {
  const endDate = new Date(initialDate)

  switch (periodicity) {
    case 'weekly':
      endDate.setDate(endDate.getDate() + 7)
      break
    case 'monthly':
      endDate.setMonth(endDate.getMonth() + 1)
      break
    case 'bimonthly':
      endDate.setMonth(endDate.getMonth() + 2)
      break
    case 'quarterly':
      endDate.setMonth(endDate.getMonth() + 3)
      break
    case 'semiannually':
      endDate.setMonth(endDate.getMonth() + 6)
      break
    case 'annually':
      endDate.setFullYear(endDate.getFullYear() + 1)
      break
  }

  return endDate
}

<script setup lang="ts">
import { formatDocument } from "../../helpers";

definePage({
  meta: {
    title: "Vendas",
    icon: "file",
  },
});

const orderStore = useStore("order");
const metaStore = useStore("meta");

const formSchema = {
  attendants: {
    description: "Atendentes",
    type: "array",
    items: {
      $ref: "user",
      indexes: ["name", "email"],
    },
    isReference: true,
    isFile: false,
    referencedCollection: "user",
  },
  broadcast: {
    description: "Broadcast",
    $ref: "broadcast",
    indexes: ["name"],
    isReference: true,
    isFile: false,
    referencedCollection: "broadcast",
  },
};
const formData = reactive<any>({});

async function createTasks() {
  if (orderStore.selected.length == 0) {
    metaStore.$actions.spawnModal({
      title: "Ops",
      body: "Selecione os pedidos",
    });
  }

  if (!formData.attendants || formData.attendants.length == 0) {
    metaStore.$actions.spawnModal({
      title: "Ops",
      body: "Selecione os atendentes",
    });
  }

  if (!formData.broadcast) {
    metaStore.$actions.spawnModal({
      title: "Ops",
      body: "Selecione o broadcast",
    });
  }

  const { result } = await aeria.order.createTasks.POST({
    orders: orderStore.selected,
    ...formData,
  });

  if (result == "ok") {
    metaStore.$actions.spawnToast({
      text: "As tarefas foram criadas com sucesso",
    });
  } else {
    metaStore.$actions.spawnModal({
      title: "Ops",
      body: "Ocorreu um erro ao criar as tarefas",
    });
  }

  orderStore.selected = [];
  orderStore.$actions.closeModal();
  orderStore.$actions.clearItem();

  orderStore.$actions.getAll({
    filters: {
      pending: true,
    },
  });
}
</script>

<template>
  <aeria-crud collection="order">
    <template #row-local_id="{ row, column }">
      <div class="tw-flex tw-flex-col tw-gap-1">
        <div>{{ row[column] }}</div>
        <div class="tw-text-[8.5pt] tw-opacity-60">
          {{ row.token }}
        </div>
      </div>
    </template>

    <template #row-customer="{ row, column }">
      <div class="tw-flex tw-flex-col tw-gap-1">
        <div>{{ row[column].name }}</div>
        <div class="tw-text-[8.5pt] tw-opacity-60">
          {{ formatDocument(row[column].document) }}
        </div>
      </div>
    </template>

    <template #row-status="{ row, column }">
      <status-badge :color="orderStore.$actions.getComputedColor(row[column])">
        {{ t(row[column] || "-") }}
      </status-badge>
    </template>

    <template #row-products="{ row, column }">
      <span v-if="!row.manual_posting" class="tw-flex tw-items-center tw-gap-1">
        <extra-icon :name="row.integration.platform"></extra-icon>
        <div>
          {{ row[column].map((product) => product.name).join(", ") }}
        </div>
      </span>

      <div v-else>
        {{ row.package_content }}
      </div>
    </template>

    <template #row-tracking_code="{ row, column }">
      <div class="tw-flex tw-items-center tw-gap-1">
        <extra-icon
          v-if="row[column]"
          :name="row.shipping_profile?.provider"
        ></extra-icon>
        <div>{{ row[column] || "-" }}</div>
      </div>
    </template>

    <template #row-date_updated="{ row, column }">
      {{
        row[column]
          ? getRelativeTimeFromNow(new Date(row[column]).getTime())
          : null
      }}
    </template>
  </aeria-crud>

  <aeria-panel
    float
    title="Criar tarefas"
    v-model="orderStore.modal"
    @overlay-click="() => orderStore.$actions.closeModal()"
    :key="orderStore.item?._id"
  >
    <aeria-form :form="formSchema" v-model="formData"></aeria-form>
    <template #footer>
      <aeria-button
        variant="transparent"
        @click="() => orderStore.$actions.closeModal()"
      >
        Cancelar
      </aeria-button>
      <aeria-button large @click="createTasks"> Criar </aeria-button>
    </template>
  </aeria-panel>
</template>

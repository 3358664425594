import { registerStore, createCollectionStore } from 'aeria-ui'

export const task = registerStore((context) => {
  const state = reactive({
    status: 'pending',
    modal: false,
  })

  return createCollectionStore(
    {
      $id: 'task',
      state,
      actions: (state, actions) => ({
        openModal() {
          actions.clearItem()
          state.modal = true
        },
        closeModal() {
          state.modal = false
        },
      }),
    },
    context,
  )
})

<script setup lang="ts">
const organizationStore = useStore('organization')

definePage({
  meta: {
    title: "Organizações",
    icon: "archive"
  }
})
</script>

<template>
  <aeria-crud collection="organization"></aeria-crud>

  <aeria-panel
    close-hint
    fixed-right
    v-model="organizationStore.modal"
    title="Assinaturas"
    @overlay-click="(organizationStore.modal = false)"
  >
    <subscriptions-table :subscriptions="organizationStore.currentSubscriptions" />
  </aeria-panel>
</template>

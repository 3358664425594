<script setup lang="ts">
import { STORE_ID } from "aeria-ui";
import Funnel from "../../../components/funnel/funnel.vue";
import { condenseItem } from "aeria-ui";

definePage({
  meta: {
    title: "Broadcast",
    icon: "share-network"
  },
  props: true
})

const props = defineProps<{
  id: string;
}>();

const broadcastStore = useStore("broadcast");
const funnelStore = useStore("funnel");
const metaStore = useStore("meta");
provide(STORE_ID, "funnel");

onMounted(async () => {
  await broadcastStore.$actions.get({
    filters: {
      _id: props.id,
    },
  });

  await funnelStore.$actions.get({
    filters: {
      _id: broadcastStore.item.funnel?._id,
    },
  });
});

async function handleSaveForm(messages: any) {
  const { result } = await aeria.funnel.updateOrCreate.POST({
    funnel: {
      ...funnelStore.item,
      messages,
    },
  })

  funnelStore.$actions.setItem(result)

  await broadcastStore.$actions.insert({
    what: condenseItem({
      ...broadcastStore.item,
      csv: null,
      funnel: funnelStore.item._id
    }),
  });

  metaStore.$actions.spawnToast({
    text: "Os dados foram salvos com sucesso",
  });
}

const handleSaveFlow = async (messages: Array<any>) => {
  funnelStore.$actions.setItem({
    ...funnelStore.item, 
    messages 
  })
};
</script>

<template>
  <funnel
    :title="broadcastStore.item.name"
    :save-disabled="false"
    @save-form="handleSaveForm"
    @save-flow="handleSaveFlow"
  >
    <template #form>
      <aeria-form
        collection="broadcast"
        :key="broadcastStore.item._id?.toString()"
        :form="broadcastStore.$actions.useProperties(['name'])"
        :layout="broadcastStore.rawDescription.formLayout"
        v-model="broadcastStore.item"
      />

      <aeria-form
        collection="funnel"
        class="tw-mt-4"
        :key="funnelStore.item._id?.toString()"
        :form="
          funnelStore.$actions.useProperties([
            'delay_duration',
            'delay_unit',
            'work_time',
            'start_time',
            'end_time',
          ])
        "
        :layout="funnelStore.rawDescription.formLayout"
        v-model="funnelStore.item"
      />
    </template>
  </funnel>
</template>
